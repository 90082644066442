import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="bg-gray-800 p-6 mb-6 flex w-full">
      <div className="w-full max-w-5xl mx-auto flex flex-wrap items-center justify-between ">
        <div className="flex items-center flex-shrink-0 mr-6 text-white">
          <Link to="/" className="text-white text-xl font-semibold tracking-tight">
            {siteTitle}
          </Link>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
